import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: function () {
      return import('../views/Login.vue')
    }
  },
  {
    path: '/Console',
    name: 'Console',
    component: function () {
      return import('../views/Console.vue')
    }
  },
  {
    path: '/Role',
    name: 'Role',
    component: function () {
      return import('../views/Role.vue')
    }
  },
  {
    path: '/MenuConfig',
    name: 'MenuConfig',
    component: function () {
      return import('../views/MenuConfig.vue')
    }
  },
  {
    path: '/UserConfig',
    name: 'UserConfig',
    component: function () {
      return import('../views/UserConfig.vue')
    }
  },
  {
    path: '/Company',
    name: 'Company',
    component: function () {
      return import('../views/Company.vue')
    }
  },
  {
    path: '/ProjectData',
    name: 'ProjectData',
    component: function () {
      return import('../views/ProjectData.vue')
    }
  },
  {
    path: '/Overview',
    name: 'Overview',
    component: function () {
      return import('../views/Overview.vue')
    }
  },
  {
    path: '/EquipmentRegistration',
    name: 'EquipmentRegistration',
    component: function () {
      return import('../views/EquipmentRegistration.vue')
    }
  },
  {
    path: '/RealtimeMonitor',
    name: 'RealtimeMonitor',
    component: function () {
      return import('../views/RealtimeMonitor.vue')
    }
  },
  {
    path: '/EarlyWarning',
    name: 'EarlyWarning',
    component: function () {
      return import('../views/EarlyWarning.vue')
    }
  },
  {
    path: '/PoliceInfo',
    name: 'PoliceInfo',
    component: function () {
      return import('../views/PoliceInfo.vue')
    }
  },
  {
    path: '/ViolationInfo',
    name: 'ViolationInfo',
    component: function () {
      return import('../views/ViolationInfo.vue')
    }
  },
  {
    path: '/DriverManagement',
    name: 'DriverManagement',
    component: function () {
      return import('../views/DriverManagement.vue')
    }
  },
  
  // {
  //   path: '/DeviceArea',
  //   name: 'DeviceArea',
  //   component: function () {
  //     return import('../views/DeviceArea.vue')
  //   }
  // },
  // {
  //   path: '/DeviceConfig',
  //   name: 'DeviceConfig',
  //   component: function () {
  //     return import('../views/DeviceConfig.vue')
  //   }
  // },
  // {
  //   path: '/RealProject',
  //   name: 'RealProject',
  //   component: function () {
  //     return import('../views/RealProject.vue')
  //   }
  // },
  // {
  //   path: '/RealTeam',
  //   name: 'RealTeam',
  //   component: function () {
  //     return import('../views/RealTeam.vue')
  //   }
  // },
  // {
  //   path: '/RealName',
  //   name: 'RealName',
  //   component: function () {
  //     return import('../views/RealName.vue')
  //   }
  // },
  // {
  //   path: '/CountRealTime',
  //   name: 'CountRealTime',
  //   component: function () {
  //     return import('../views/CountRealTime.vue')
  //   }
  // },
  // {
  //   path: '/CountDetail',
  //   name: 'CountDetail',
  //   component: function () {
  //     return import('../views/CountDetail.vue')
  //   }
  // },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if ( (to.name !== 'Login') && !localStorage.accessToken) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
