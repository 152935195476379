<template>
  <div id="app">
    <div v-if="$route.name == 'Default' || $route.name == 'Login'">
      <router-view />
    </div>
    <div v-else>
      <Menu ref="menu" />
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Menu,
    Footer,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    $route: {
      handler (val, old) {
        if (val.name != 'Login') {
          var projectId = localStorage.getItem('projectId')
          if (!projectId) {
            console.log('调用子组件方法');
            setTimeout(() => {
              this.$refs.menu.handleProject()
            }, 100);
          }
        }
      }
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.icon, .iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f0f0f0;
  color: #303133;
}
h2 {
  font-size: 18px;
  color: #303133;
  font-weight: normal;
  margin-bottom: 20px;
}
.searchWrap {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  .searchInput {
    width: 200px;
    margin: 0 20px;
  }
}
.sb {
  display: flex;
  // justify-content: space-between;
  align-items: center;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
