<template>
  <div class="menu">
    <div class="menu-header">
      <div class="menu-header-left">
        <img src="../assets/logo.png" />
        <h1>晴鑫塔式起重机管理平台</h1>
      </div>
      <div class="menu-header-right">
        <el-button type="text" @click="handleProject" icon="el-icon-s-operation">
          <span v-if="!projectId || !projectName">请选择项目</span>
          <span v-if="!!projectId && !!projectName">{{projectName}}</span>
        </el-button>
        <el-button type="text" icon="el-icon-setting" @click="handlePassword">修改密码</el-button>
        <el-button type="text" icon="el-icon-switch-button" @click="handleLogin">退出登陆</el-button>
      </div>
    </div>
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-submenu
        v-for="(item, index) in menuData"
        :key="index"
        :index="item.code"
        v-if="hasChildren(item)"
      >
        <template slot="title">{{ item.name }}</template>
        <el-menu-item
          v-for="(item2, index2) in item.children"
          :key="index2"
          :index="item2.code"
          >{{ item2.name }}</el-menu-item
        >
      </el-submenu>
      <el-menu-item v-else :index="item.code">{{ item.name }}</el-menu-item>
    </el-menu>
    
    <el-drawer
      title="项目列表"
      :visible.sync="showProjectList"
      direction="rtl"
      width="50%"
      :before-close="handleClose">
      <div style="padding:20px;">
      <el-table
        :data="projectList"
        style="width: 100%;">
        <el-table-column
          prop="projectName"
          label="项目名称">
        </el-table-column>
        <el-table-column align="right" width="50px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="setProjectId(scope.row)"
              >进入</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      </div>
    </el-drawer>

    <el-dialog
      title="修改密码"
      :visible.sync="showPass"
      width="600px"
      :before-close="handlePassClose">
      <el-form :model="formData" :rules="formRules" ref="passForm" label-width="80px">
        <el-form-item label="用户ID" prop="id">
          <el-input v-model="formData.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="formData.oldPassword" clearable show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="formData.newPassword" clearable show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPassword2">
          <el-input v-model="formData.newPassword2" clearable show-password></el-input>
        </el-form-item>
      </el-form>
      <div class="intro">
        密码修改成功后将退出当前登录，请使用新密码重新登录系统
      </div>
      <div class="intro">
        密码中必须包含大小写 字母、数字、特称字符，至少8个字符，最多30个字符
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handlePassClose">取 消</el-button>
        <el-button type="primary" @click="handlePassSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { FormItem } from "element-ui";

export default {
  name: "Menu",
  props: {
    params: String,
  },
  data() {
    return {
      showProjectList: false,
      showPass: false,
      formData: {
        id: localStorage.getItem('userId'),
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      },
      formRules: {
        oldPassword: [{ required: true, message: "必填项", trigger: "blur" }],
        newPassword: [{ required: true, message: "必填项", trigger: "blur" }],
        newPassword2: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      projectList: [],
      
    };
  },
  computed: {
    isAdmin: function () {
      return localStorage.getItem("authority") == "administrator";
    },
    menuData: function () {
      return JSON.parse(localStorage.getItem("menuData"));
    },
    userName: function () {
      return localStorage.getItem("userName");
    },
    activeIndex: function () {
      return this.$route.name;
    },
    projectId: function () {
      return localStorage.getItem("projectId");
    },
    projectName: function () {
      return localStorage.getItem("projectName");
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      if (key == "Login") {
        this.$confirm("此操作将退出登陆, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.clear();
            this.$router.push({
              name: "Login",
            });
            this.$message({
              type: "success",
              message: "已退出",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else if (this.$route.name != key) {
        this.$router.push({
          name: key,
        });
      }
    },
    handleLogin() {
      this.$confirm("此操作将退出登陆, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.$router.push({
            name: "Login",
          });
          this.$message({
            type: "success",
            message: "已退出",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 判断是否叶子节点
    hasChildren: function (item) {
      return item.hasOwnProperty("children");
    },
    // 切换项目
    handleProject: function () {
      var that = this;
      var data = {
        pageSize: 0,
        pageNum: 1,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/project/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.projectList = res.data.dataList;
            that.showProjectList = true
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 进入项目
    setProjectId: function (row) {
      console.log(row.projectId, row.projectName);
      localStorage.setItem('projectId', row.projectId)
      localStorage.setItem('projectName', row.projectName)
      this.handleClose()
      location.reload()
    },
    // 关闭
    handleClose: function () {
      var projectId = localStorage.getItem('projectId')
      var projectName = localStorage.getItem('projectName')
      console.log(!projectId || !projectName);
      if (!projectId) {
        this.$alert('请选择您要进入的项目', '温馨提示', {
          confirmButtonText: '确定'
        });
      } else {
        this.showProjectList = false
      }
    },
    // 修改密码
    handlePassword: function () {
      this.showPass = true
    },
    // 关闭修改密码
    handlePassClose: function () {
      this.formData.oldPassword = ''
      this.formData.newPassword = ''
      this.formData.newPassword2 = ''
      this.showPass = false
      this.$refs['passForm'].resetFields();
    },
    // 提交修改密码
    handlePassSubmit: function () {
      this.$refs["passForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将修改密码并退出登录, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onPassSubmit()
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行修改密码
    onPassSubmit: function () {
      if (this.formData.oldPassword == this.formData.newPassword) {
        this.$message({
          type: "info",
          message: "新密码与原密码重复",
        });
        return
      }
      if (this.formData.newPassword != this.formData.newPassword2) {
        this.$message({
          type: "info",
          message: "新密码与确认密码不一致",
        });
        return
      }
      var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
      if (!pwdRegex.test(this.formData.newPassword)) {
        this.$message({
          type: "info",
          message: "您的密码复杂度太低（密码中必须包含大小写 字母、数字、特称字符，至少8个字符，最多30个字符；）",
        });
        return
      }
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/sysUser/newPassword",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            localStorage.clear();
            that.$router.push({
              name: "Login",
            });
            that.$message({
              type: "success",
              message: "请重新登录",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.menu-header,
.menu-header-left,
.menu-header-right {
  background-color: #07002e;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin-left: 20px;
    width: 60px;
    height: 60px;
  }
  h1 {
    margin-left: 20px;
    font-size: 30px;
    color: #ffffff;
  }
  .inline {
    display: inline;
  }
}
.menu-header-right {
  margin-right: 100px;
  color: #ffffff;
}
.intro {
  text-align: right;
  font-size: 12px;
  color: #999;
}
</style>
